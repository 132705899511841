import React from "react";
import { Layout } from "../../components/Layout";
import { Head } from "../../components/Head";
import { CaseStudiesTitle } from "../../components/CaseStudiesTitle";
import { CaseStudiesText } from "../../components/CaseStudiesText";

export default function CS_Psychosomatic() {
  const title = "Psychosomatic";
  const subTitle = "心療内科";
  const overview = [
    "近年増加傾向になる診療科目です",
    <br />,
    "心療内科の開業は全ての診療科の中で一番開業資金が少ないです",
  ];
  const openingCash = "おおよその開業資金";
  const openingCashText = [
    "土地、建物　7,000万円～15,000万円",
    <br />,
    "設備　400万円〜",
    <br />,
    "電子カルテ、レジスター、コピー複合機、診察用ベッド",
  ];
  const point = "ポイント";
  const pointText = [
    "・患者様の心理を考慮する",
    <br />,
    "・プライバシーに配慮した内装",
    <br />,
    "・インターネット広告での集患",
    <br />,
    <br />,
    "心療内科に来院される患者様の多くは、通院していることを他人に知られたくない気持ちがあるため、",
    <br />,
    "駅前のビル1階での開業をしないように心がける",
    <br />,
    "患者様が気軽に話せるような配色等にし、仕切りパネル等を設けることが重要です",
  ];

  return (
    <Layout>
      <Head props={subTitle} />
      <CaseStudiesTitle title={[title, subTitle]} />
      <CaseStudiesText
        textData={[overview, openingCash, openingCashText, point, pointText]}
      />
    </Layout>
  );
}
